import { t } from 'i18next'
import React from 'react'
import { useEffect, useReducer, useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import Select from 'react-select'

import { useGetStampOptionsQuery } from '../../../../api/services/stampsApi'
import { clientStyles } from '../../../../data/createStampSelectStyles'
import TextInputRegular from '../../../inputs/TextInputRegular'
import useFilteredOptions from '../hooks/useFilteredOptions'
import ActionButtons from './ActionButtons'
import AddField from './buttons/AddField'

const ListOfFields = ({ spredValues, nonSelectStampFields, setInputFields, setNonSelectStampFields }) => {
  const { data: stampOptions } = useGetStampOptionsQuery()

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0)

  const [inputFieldsArray, setInputFieldsArray] = useState([])

  useEffect(() => {
    const array = spredValues.concat(nonSelectStampFields)

    setInputFieldsArray(array)
  }, [spredValues, nonSelectStampFields, reducerValue])

  // This is the list of default fields user can choose from
  // There CAN'T be duplicate default keys
  // Exp. Only one "Klijent" field can be present on the stamp
  // The other "Klijent" field whold have to be inputed manualy
  const filteredOptions = useFilteredOptions(stampOptions, spredValues)

  //Set attributeKey
  const handleChange = (index, e, nonSelect, inputField) => {
    if (!nonSelect) {
      const values = [...spredValues]
      const indexInArr = values.findIndex((el) => el.uId === inputField.uId)
      const newObject = { ...values[indexInArr], attributeKey: e.label }
      values.splice(indexInArr, 1, newObject)
      setInputFields(values)
    }
    if (nonSelect) {
      const values = [...nonSelectStampFields]
      const indexInArr = values.findIndex((el) => el.uId === inputField.uId)
      const newObject = { ...values[indexInArr], attributeKey: e.target.value }
      values.splice(indexInArr, 1, newObject)

      setNonSelectStampFields(values)
    }
  }
  //Set attributeValue
  const handleValueChange = (index, e, nonSelect, inputField) => {
    if (!nonSelect) {
      const values = [...spredValues]
      const indexInArr = values.findIndex((el) => {
        return el.uId === inputField.uId
      })
      values[indexInArr]['attributeValue'] = e.target.value
      setInputFields(values)
    }
    if (nonSelect) {
      const values = [...nonSelectStampFields]
      const indexInArr = values.findIndex((el) => el.uId === inputField.uId)
      values[indexInArr][e.target.name] = e.target.value
      setNonSelectStampFields(values)
    }
  }

  //Select to swho on protocol book table
  const showField = (index, nonSelect, inputField) => {
    if (nonSelect) {
      const values = [...nonSelectStampFields]
      const indexInArr = values.findIndex((el) => el.uId === inputField.uId)

      switch (values[indexInArr]['isVisible']) {
        case null:
          values[indexInArr]['isVisible'] = true
          break
        case undefined:
          values[indexInArr]['isVisible'] = true
          break
        case true:
          values[indexInArr]['isVisible'] = false
          break
        default:
          values[indexInArr]['isVisible'] = true
          break
      }

      setNonSelectStampFields(values)
    } else {
      const values = [...spredValues]
      const indexInArr = values.findIndex((el) => el.uId === inputField.uId)

      switch (values[indexInArr]['isVisible']) {
        case null:
          values[indexInArr]['isVisible'] = true
          break
        case undefined:
          values[indexInArr]['isVisible'] = true
          break
        case true:
          values[indexInArr]['isVisible'] = false
          break
        default:
          values[indexInArr]['isVisible'] = true
          break
      }

      setInputFields(values)
    }
  }

  return (
    <div className="w-full">
      {inputFieldsArray.map((inputField, index) => {
        if (inputField.attributeKey === 'Stamp ID') {
          return (
            <>
              {/* <div className="flex w-full items-center p-[8px] gap-[8px]" key={index}> */}
              <div className="grid grid-cols-[2rem_1fr_1fr_3rem] w-full items-center p-2 gap-2" key={index}>
                {/* Checkbox for showing stampField dynamically on protocol book table */}
                <div className="">
                  <div
                    className="w-full flex justify-center tooltip tooltip-right hover:cursor-pointer"
                    data-tip={t('info')}
                  >
                    <FaInfoCircle size={20} className="mb-2 tooltip tooltip-right " />
                  </div>

                  <input
                    type="checkbox"
                    className="checkbox checkbox-md border-[#A6A9AD] [--chkbg:theme(colors.white)] [--chkfg:black] mt-1"
                    onChange={() => showField(index, false, inputField)}
                    id={inputField.attributeKey}
                    checked={true}
                  />
                </div>

                <div>
                  <label className="text-sm">{t('stamp_field')}</label>
                  <TextInputRegular value="Šifra pečata" readOnly name="attributeKey" />
                </div>
                <div>
                  <label className="text-sm">{t('stamp_field_value')}</label>
                  <TextInputRegular defaultValue="" readOnly name="attributeValue" />
                </div>
                <div className="mt-6">
                  <AddField
                    spredValues={spredValues}
                    nonSelectStampFields={nonSelectStampFields}
                    setNonSelectStampFields={setNonSelectStampFields}
                    setInputFields={setInputFields}
                    stampOptions={stampOptions}
                    index={index}
                  />
                </div>
              </div>
            </>
          )
        }

        // Select stamp fields
        if (
          inputField.attributeKey === 'Predmet' ||
          inputField.attributeKey === 'Broj fakture' ||
          inputField.attributeKey === 'Klijent' ||
          inputField.attributeKey === 'Partner' ||
          inputField.attributeKey === 'Dobavljač' ||
          inputField.attributeKey === 'Kupac' ||
          inputField.attributeKey === 'Mjesto' ||
          inputField.attributeKey === 'Sjedište' ||
          inputField.attributeKey === 'Datum dokumenta' ||
          inputField.attributeKey === 'Datum predmeta' ||
          inputField.attributeKey === 'Datum protokola' ||
          (inputField.attributeKey === '' && inputField.select) // This is for new select field
        ) {
          return (
            // <div className="flex w-full items-center p-2 gap-2" key={index}>
            <div className="grid grid-cols-[2rem_1fr_1fr_3rem] w-full items-center p-2 gap-2" key={index}>
              {/* Checkbox for showing stampField dynamically on protocol book table */}
              <input
                type="checkbox"
                className="checkbox checkbox-md border-[#A6A9AD] [--chkbg:theme(colors.white)] [--chkfg:black]"
                onChange={() => showField(index, false, inputField)}
                id={inputField.attributeKey}
                checked={inputFieldsArray[index]?.isVisible || ''}
              />

              <Select
                options={filteredOptions}
                styles={clientStyles}
                value={{
                  label: inputFieldsArray[index]?.attributeKey,
                  value: inputFieldsArray[index]?.attributeKey,
                }}
                placeholder="Odaberi polje"
                onChange={(e) => handleChange(index, e, false, inputField)}
              />
              <TextInputRegular
                type="text"
                value={inputFieldsArray[index]?.attributeValue}
                onChange={(e) => handleValueChange(index, e, false, inputField)}
              />
              <ActionButtons
                stampOptions={stampOptions}
                inputFieldsArray={inputFieldsArray}
                spredValues={spredValues}
                nonSelectStampFields={nonSelectStampFields}
                setNonSelectStampFields={setNonSelectStampFields}
                setInputFields={setInputFields}
                forceUpdate={forceUpdate}
                inputField={inputField}
                nonSelect={false}
                index={index}
                shouldShow={
                  inputFieldsArray[index]?.attributeKey === 'Predmet' ||
                  inputFieldsArray[index]?.attributeKey === 'Broj fakture' ||
                  inputFieldsArray[index]?.attributeKey === 'Klijent' ||
                  inputFieldsArray[index]?.attributeKey === 'Partner' ||
                  inputFieldsArray[index]?.attributeKey === 'Dobavljač' ||
                  inputFieldsArray[index]?.attributeKey === 'Kupac' ||
                  inputFieldsArray[index]?.attributeKey === 'Mjesto' ||
                  inputFieldsArray[index]?.attributeKey === 'Sjedište' ||
                  inputFieldsArray[index]?.attributeKey === 'Datum dokumenta' ||
                  inputFieldsArray[index]?.attributeKey === 'Datum predmeta' ||
                  inputFieldsArray[index]?.attributeKey === 'Datum protokola' ||
                  inputFieldsArray[index]?.attributeKey === 'Valuta' ||
                  inputFieldsArray[index]?.attributeKey === 'PDV' ||
                  inputFieldsArray[index]?.attributeKey === 'Vrijednost bez PDV'
                    ? false
                    : true
                }
              />
            </div>
          )
        }

        // non select stampFields
        if ((nonSelectStampFields.length !== 0 && !inputField.select) || inputField.api) {
          return (
            <div className="grid grid-cols-[2rem_auto_auto_3rem]  w-full items-center p-2 gap-2" key={index}>
              {/* Checkbox for showing stampField dynamically on protocol book table */}
              <input
                type="checkbox"
                className="checkbox checkbox-md border-[#A6A9AD] [--chkbg:theme(colors.white)] [--chkfg:black]"
                // readOnly
                onChange={() => showField(index, true, inputField)}
                id={inputField.attributeKey}
                checked={inputFieldsArray[index]?.isVisible || ''}
              />

              <TextInputRegular
                value={inputField.attributeKey}
                name="attributeKey"
                onChange={(e) => handleChange(index, e, 'nonSelect', inputField)}
              />
              <TextInputRegular
                value={inputField.api ? inputField.api_url : inputField.attributeValue}
                name={inputField.api ? 'api_url' : 'attributeValue'}
                placeholder={inputField.api ? '/some/api' : ''}
                onChange={(e) => handleValueChange(index, e, 'nonSelect', inputField)}
              />

              <ActionButtons
                stampOptions={stampOptions}
                inputFieldsArray={inputFieldsArray}
                spredValues={spredValues}
                nonSelectStampFields={nonSelectStampFields}
                setNonSelectStampFields={setNonSelectStampFields}
                setInputFields={setInputFields}
                // forceUpdate={forceUpdate} //for SwitchField.jsx component
                inputField={inputField}
                nonSelect={true}
                index={index}
                shouldShow={
                  inputFieldsArray[index]?.attributeKey === 'Predmet' ||
                  inputFieldsArray[index]?.attributeKey === 'Broj fakture' ||
                  inputFieldsArray[index]?.attributeKey === 'Klijent' ||
                  inputFieldsArray[index]?.attributeKey === 'Partner' ||
                  inputFieldsArray[index]?.attributeKey === 'Dobavljač' ||
                  inputFieldsArray[index]?.attributeKey === 'Kupac' ||
                  inputFieldsArray[index]?.attributeKey === 'Mjesto' ||
                  inputFieldsArray[index]?.attributeKey === 'Sjedište' ||
                  inputFieldsArray[index]?.attributeKey === 'Datum dokumenta' ||
                  inputFieldsArray[index]?.attributeKey === 'Datum predmeta' ||
                  inputFieldsArray[index]?.attributeKey === 'Datum protokola' ||
                  inputFieldsArray[index]?.attributeKey === 'Valuta' ||
                  inputFieldsArray[index]?.attributeKey === 'PDV' ||
                  inputFieldsArray[index]?.attributeKey === 'Vrijednost bez PDV'
                    ? false
                    : true
                }
              />
            </div>
          )
        }
      })}
    </div>
  )
}

export default ListOfFields
