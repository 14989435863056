import fontkit from '@pdf-lib/fontkit'
import { format } from 'date-fns'
import { t } from 'i18next'
import { degrees, PDFDocument, rgb } from 'pdf-lib'
import { useEffect, useMemo, useState } from 'react'
import { FaEdit } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useAsyncDebounce } from 'react-table'
import 'react-toastify/dist/ReactToastify.css'

import { setSendFile } from '../../../api/features/fileSlice'
import { useGetCategoryQuery } from '../../../api/services/categoriesApi'
import { usePaginatedProtocolQuery } from '../../../api/services/protocolApi'
import { useGetCustomStampsQuery } from '../../../api/services/stampsApi'
import { EditDocumentModal } from '../../modals/modals'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import SelectColumnFilterPaginated from '../components/SelectColumnFilterPaginated'
import NewTable from '../NewTable'

const TableConfigFiles = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const company = useSelector((state) => state.company.company)
  const { data: category } = useGetCategoryQuery(company?.id)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (key === 'Datum prijema') {
        if (value.trim() !== '') {
          const formattedDate = format(new Date(value), 'dd-MM-yyyy')
          queryString += `Datum prijema=${encodeURIComponent(formattedDate)}&`
        }
      } else {
        if (value.trim() !== '') {
          queryString += `${key}=${encodeURIComponent(value)}&`
        }
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const columns = useMemo(
    () => [
      {
        Header: t('stamp_key'),
        accessor: 'Sifra',
      },
      {
        Header: t('subject'),
        accessor: 'Predmet',
      },
      {
        Header: t('client'),
        accessor: 'Klijent',
      },
      {
        Header: t('location'),
        accessor: 'Lokacija',
      },
      {
        Header: t('date_of_prijem'),
        accessor: 'Datum prijema',
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
      {
        Header: 'Status',
        accessor: 'Status',
        Cell: ({ row }) => {
          let status =
            row.original.Status === 'Approved'
              ? t('approved')
              : row.original.Status === 'Rejected'
                ? t('rejected')
                : t('awaiting')

          const color =
            row.original.Status === 'Approved'
              ? 'bg-main-green'
              : row.original.Status === 'Rejected'
                ? 'bg-red-600'
                : 'bg-primary'

          return <div className={`${color} text-white rounded-lg font-thin`}>{status}</div>
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={[
                { label: t('approved'), value: 1 },
                { label: t('rejected'), value: 2 },
                { label: t('awaiting'), value: 3 },
              ]}
            />
          )
        },
      },
      {
        Header: t('type'),
        accessor: 'Tip',
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={[
                { label: 'Računovodstveni', value: 1 },
                { label: 'Pravni', value: 2 },
              ]}
            />
          )
        },
      },
      {
        Header: t('category'),
        accessor: 'Kategorije',
        Cell: ({ row }) => {
          let category = row?.original?.Kategorije
          if (!category) return t('no_category')
          return category
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={category?.map((item) => {
                return {
                  label: item?.categoryName,
                  value: item?.id,
                }
              })}
            />
          )
        },
      },
      {
        Header: t('kind'),
        accessor: 'advance',
        Cell: ({ row }) => {
          let advance = row.original.advance === 1 ? t('advance') : ''
          let clasified = row.original.clasified === 1 ? t('clasified') : ''
          let importt = row.original?.importOption === 1 ? t('import') : ''
          let exportt = row.original?.exportOption === 1 ? t('export') : ''
          let reExport = row.original.reExport === 1 ? 're-export' : ''
          let recieved = row.original.received === 1 ? t('recieved') : ''
          let issued = row.original.issued === 1 ? t('clasified') : ''
          let KO = row.original.KO === 1 ? t('K.O_document') : ''
          return (
            <div className="flex flex-col w-full justify-center items-center">
              <p className="mb-0">{advance}</p>
              <p className="mb-0">{importt}</p>
              <p className="mb-0">{exportt}</p>
              <p className="mb-0">{clasified}</p>
              <p className="mb-0">{reExport}</p>
              <p className="mb-0">{recieved}</p>
              <p className="mb-0">{issued}</p>
              <p className="mb-0">{KO}</p>
            </div>
          )
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              advancedOptions
              handleFilterChange={handleFilterChange}
              providedOptions={[
                { label: 'Advance', value: 1 },
                { label: 'Import', value: 2 },
                { label: 'Export', value: 3 },
                { label: 'Re-export', value: 4 },
                { label: 'Recieved', value: 5 },
                { label: 'Issued', value: 6 },
                { label: 'KO', value: 7 },
              ]}
            />
          )
        },
      },
    ],
    [category],
  )

  //Edit checkbox categories state's
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState({})
  const [fileTypes, setFileTypes] = useState({
    invoice: false,
    inSlide: false,
    recived: false,
    outSlide: false,
    advanced: false,
    reExport: false,
    koDoc: false,
  })

  const [selectedFile, setSelectedFile] = useState()
  // const [prevStampKey, setPrevStampKey] = useState("")

  //Status, type & company category

  //State for printing stamp on PDF
  const [coords, setCoords] = useState({ x: 11, y: 630 })
  const [coordsRect, setCoordsRect] = useState({ x: coords.x - 1, y: 630 })
  const [coords1, setCoords1] = useState({ x: 111, y: 630 })
  const [coordsRect1, setCoordsRect1] = useState({ x: coords1.x - 1, y: 630 })
  const [urlPdf, setUrlPdf] = useState()
  const [totalPages, setTotalPages] = useState(1)
  const [totalHeght, setTotalHeight] = useState(0)
  const [statePage, setStatePage] = useState(undefined)
  const [stampPositionState, setStampPositionState] = useState(null)
  const [rotate, setRotate] = useState(0)
  const fileName = selectedFile?.name

  // Filters

  const dispatch = useDispatch()

  //Value for requesting only files of specific company or user

  const tableHooksArray = [
    {
      label: 'Uredi',
      Icon: FaEdit,
      onClick: (row) => {
        let advance = row.original.advance === 1
        let koDoc = row.original.KO === 1
        let issued = row.original?.issued === 1
        let isimport = row.original.importOption === 1
        let isExport = row.original.exportOption === 1
        let received = row.original.received === 1
        let reExport = row.original.reExport === 1
        setOpen(true)
        setFile(row.original)

        setFileTypes({
          advanced: advance,
          inSlide: isimport,
          issued,
          outSlide: isExport,
          reExport,
          koDoc,
          received,
        })
      },
      disabled: false,
    },
  ]

  let FILEDATAissued = fileTypes.issued ? 'issued' : ''
  let FILEDATAimportOption = fileTypes.inSlide ? 'import' : ''
  let FILEDATAexportOption = fileTypes.outSlide ? 'export' : ''
  let FILEDATAKO = fileTypes.koDoc ? 'KO' : ''
  let FILEDATAreceived = fileTypes.received ? 'received' : ''
  let FILEDATAadvance = fileTypes.advanced ? 'advance' : ''
  let FILEDATAReexport = fileTypes.reExport ? 'reexport' : ''
  // console.log(file)

  const { data: companyStampFields } = useGetCustomStampsQuery({ companyId: company?.id })

  const hasLocationField = companyStampFields?.find(
    (field) => field.attributeKey === 'Mjesto' || field.attributeKey === 'Sjedište',
  )

  //Extracting stamp from PDF
  const stampData = Object.fromEntries(
    Object.entries(file)
      .filter((entry) => {
        if (entry[1] === null) return
        if (
          entry[0] === 'Status' ||
          entry[0] === 'Kategorije' ||
          entry[0] === 'Tip' ||
          entry[0] === 'address' ||
          entry[0] === 'address_no_stamp' ||
          entry[0] === 'id' ||
          entry[0] === 'fileName' ||
          entry[0] === 'note' ||
          entry[0] === 'issued' ||
          entry[0] === 'importOption' ||
          entry[0] === 'exportOption' ||
          entry[0] === 'reExport' ||
          entry[0] === 'advance' ||
          entry[0] === 'KO' ||
          entry[0] === 'booked' ||
          entry[0] === 'isDeleted' ||
          entry[0] === 'typeId' ||
          entry[0] === 'categoryId' ||
          entry[0] === 'statusId' ||
          entry[0] === 'folder' ||
          entry[0] === 'uploaderId' ||
          entry[0] === 'createdAt' ||
          entry[0] === 'updatedAt' ||
          entry[0] === 'companyId' ||
          entry[0] === 'clientId' ||
          entry[0] === 'comments' ||
          entry[0] === 'received'
        ) {
          return
        }
        if (entry[0] === 'Sifra') {
          return ['Šifra', entry[1]]
        }

        return entry
      })
      .concat([
        [
          'Tip dokumenta',
          `${FILEDATAissued ? 'izlazni' : ''} 
          ${FILEDATAimportOption ? 'uvoz' : ''} 
          ${FILEDATAexportOption ? 'izvoz' : ''} 
          ${FILEDATAKO ? 'KO' : ''} 
          ${FILEDATAreceived ? 'ulazni' : ''} 
          ${FILEDATAadvance ? 'avansni dokument' : ''}
          ${FILEDATAReexport ? 'reexport' : ''}`.trim(),
        ],
      ]),
  )

  // console.log('STAMP DATA', stampData)
  //Function that draws stamp on to PDF
  async function modifyPdf() {
    const existingPdfBytes = await selectedFile?.arrayBuffer()

    // Load a PDFDocument from the existing PDF bytes
    let pdfDoc
    if (existingPdfBytes) {
      const docPDF = await PDFDocument.load(existingPdfBytes)
      pdfDoc = docPDF
    }

    // Embed the Helvetica font
    const url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf'
    const fontBytes = await fetch(url).then((res) => res.arrayBuffer())
    pdfDoc?.registerFontkit(fontkit)
    const helveticaFont = await pdfDoc?.embedFont(fontBytes)

    // Get the first page of the document
    const pages = pdfDoc?.getPages()
    const firstPage = pages ? pages[statePage?.value] : null

    setTotalPages(pages?.length)
    let inc = 0
    let prevInc = 0
    let prevHeightFactor = 0
    let prevTextHeight = 0
    //If stamp is rotated sort array backward for display purposes
    const tt = Object.entries(stampData)

    // DO NOT DELETE THIS!!!!!
    // IT'S CORRECT CODE
    // IT MAY BE IMPLEMENTED LATER
    // if (company?.logo) {
    //Drawing image on PDF
    // const jpgUrl = 'https://pdf-lib.js.org/assets/cat_riding_unicorn.jpg'
    // // const jpgUrl = company?.logo
    // const jpgImageBytes = await fetch(jpgUrl).then((res) => {
    //   return res.arrayBuffer()
    // })
    // const jpgImage = await pdfDoc?.embedJpg(jpgImageBytes)
    // tt.splice(tt.length, 0, ['logo', jpgImage])
    // }

    for (let i = tt.length - 1; i >= 0; i--) {
      inc = inc + 15

      if (tt[i][0] === 'Tip dokumenta') {
        const textSize = 10
        const currentTextLength = tt[i][1].toString().length
        const maxTextLength = 20
        const heightFactor = Math.ceil(currentTextLength / maxTextLength)
        let textHeight = helveticaFont?.heightAtSize(textSize + 3) * heightFactor

        firstPage?.drawRectangle({
          x:
            rotate === 90
              ? coordsRect1.x - prevTextHeight + 95
              : rotate === 180
                ? coordsRect.x + 200
                : rotate === 270
                  ? coordsRect1.x + prevTextHeight - (heightFactor !== 1 ? 130 : 115)
                  : coordsRect.x,
          y:
            rotate === 90
              ? coordsRect.y
              : rotate === 270
                ? coordsRect.y + 100
                : rotate === 0
                  ? coordsRect.y + prevTextHeight - (heightFactor !== 1 ? 24.5 * heightFactor : 35)
                  : rotate === 180
                    ? coordsRect.y - prevTextHeight + 174.5
                    : coordsRect.y + inc,
          width: 200,
          height: textHeight,
          borderColor: rgb(0, 0.1, 0.9),
          borderWidth: 1,
          rotate: degrees(rotate),
        })

        firstPage?.drawText(tt[i][1].toString(), {
          x:
            rotate === 90
              ? coordsRect1.x - prevTextHeight + 92
              : rotate === 180
                ? coordsRect.x + 115
                : rotate === 270
                  ? coordsRect1.x + prevTextHeight - 100 - 9
                  : rotate === 0
                    ? coordsRect.x + 3 + (tt[i][1].length <= 6 ? 80 : tt[i][1].length <= 13 ? 70 : 30)
                    : coordsRect.x + 3,
          y:
            rotate === 90
              ? coordsRect.y + 87
              : rotate === 270
                ? coordsRect.y + 15
                : rotate === 0
                  ? coordsRect.y + prevTextHeight - 32
                  : rotate === 180
                    ? coordsRect.y - prevTextHeight + 172.5
                    : coordsRect.y + inc,
          size: textSize,
          maxWidth: 200,
          // wordBreaks: 'yes',
          lineHeight: textSize,
          font: helveticaFont,
          color: rgb(0, 0.1, 0.9),
          rotate: degrees(rotate),
        })
      } else {
        const textSize = 10
        const currentTextLength = tt[i][1]?.toString()?.length
        const maxTextLength = 20
        const heightFactor = Math.ceil(currentTextLength / maxTextLength)
        let textHeight = helveticaFont?.heightAtSize(textSize + 3) * heightFactor
        inc = inc * heightFactor

        if (heightFactor !== 1 && rotate === 0) {
          inc = inc - 15 * heightFactor
        }
        if (heightFactor !== 1 && rotate === 90) {
          inc = inc - prevInc
        }
        if (rotate === 90 && i === tt.length - 1) {
          inc = inc - prevInc - 15
        }

        //Key texxt
        firstPage?.drawText(
          tt[i][0] === 'Sifra'
            ? 'Šifra pečata'
            : tt[i][0] === 'Lokacija' && hasLocationField
              ? hasLocationField?.attributeKey
              : tt[i][0],
          {
            x:
              rotate === 90
                ? coords1.x - prevTextHeight - 5 + 80
                : rotate === 180
                  ? coords.x + 200 - 3
                  : rotate === 270
                    ? // ? coords1.x + prevTextHeight - 100 + (heightFactor === 1 ? 5 : 11) * heightFactor
                      coords1.x + prevTextHeight - 100 + 3
                    : coords.x,
            y:
              rotate === 90
                ? coords.y + 3
                : rotate === 180
                  ? coords1.y - prevTextHeight + 110 - textHeight + 60
                  : rotate === 270
                    ? coords.y - 3 + 100
                    : rotate === 0
                      ? coords1.y + prevTextHeight + 3 - 20
                      : coords.y + 3 + inc,
            size: textSize,
            font: helveticaFont,
            color: rgb(0, 0.1, 0.9),
            rotate: degrees(rotate),
          },
        )

        // //StampKey columns
        firstPage?.drawRectangle({
          x:
            rotate === 90
              ? coordsRect1.x - prevTextHeight + 80
              : rotate === 180
                ? coordsRect.x + 100
                : rotate === 270
                  ? coordsRect1.x + prevTextHeight - 100
                  : coordsRect.x,
          y:
            rotate === 90
              ? coordsRect.y
              : rotate === 270
                ? coordsRect.y
                : rotate === 0
                  ? coordsRect.y + prevTextHeight - 20
                  : rotate === 180
                    ? coordsRect.y - prevTextHeight + 100 + 60
                    : coordsRect.y + inc,
          width: 100,
          height: textHeight,
          borderColor: rgb(0, 0.1, 0.9),
          borderWidth: 1,
          rotate: degrees(rotate),
        })

        //Value text
        firstPage?.drawText(tt[i][1]?.toString(), {
          x:
            rotate === 90
              ? coords1.x - prevTextHeight - (heightFactor === 1 ? 5 : 13.5) * heightFactor + 80
              : rotate === 180
                ? coords1.x - 3
                : rotate === 270
                  ? coords1.x + prevTextHeight - 100 + (heightFactor === 1 ? 5 : 12) * heightFactor
                  : coords1.x,
          y:
            rotate === 90
              ? coords1.y + 101
              : rotate === 180
                ? coords1.y - prevTextHeight + 110 - textHeight + 60
                : rotate == 270
                  ? coords1.y - 1
                  : rotate === 0 && heightFactor === 1
                    ? coords1.y + prevTextHeight + 3 - 20
                    : rotate === 0 && heightFactor !== 1
                      ? coords1.y + prevTextHeight + textHeight - 10 - 20
                      : coords1.y + 3 + inc,
          size: textSize,
          maxWidth: 100,
          // wordBreaks: 'yes',
          lineHeight: textSize,
          font: helveticaFont,
          color: rgb(0, 0.1, 0.9),
          rotate: degrees(rotate),
        })
        // //Value columns
        firstPage?.drawRectangle({
          x:
            rotate === 90
              ? coordsRect1.x - prevTextHeight + 80
              : rotate === 180
                ? coordsRect1.x + 100
                : rotate === 270
                  ? coordsRect1.x + prevTextHeight - 100
                  : coordsRect1.x,
          y:
            rotate === 90
              ? coordsRect1.y + 100
              : rotate === 270
                ? coordsRect1.y + 100
                : rotate === 0
                  ? coordsRect.y + prevTextHeight - 20
                  : rotate === 180
                    ? coordsRect.y - prevTextHeight + 100 + 60
                    : coordsRect1.y + inc,
          width: 100,
          height: textHeight,
          borderColor: rgb(0, 0.1, 0.9),
          borderWidth: 1,
          rotate: degrees(rotate),
        })

        prevTextHeight = prevTextHeight + textHeight
        setTotalHeight(prevTextHeight)
        if (i === tt.length) {
          prevInc = 0
          prevHeightFactor = 0
        } else {
          prevInc = inc
          prevHeightFactor = heightFactor
        }
      }
    }

    const pdfBytes = await pdfDoc?.save()
    const newFile = new File([pdfBytes], fileName, {
      type: 'application/pdf',
    })
    const bytes = new Uint8Array(pdfBytes)
    const blob = new Blob([bytes], { type: 'application/pdf' })
    const docUrl = URL.createObjectURL(blob)
    const sendFile = new File([blob], newFile.name)
    setUrlPdf(docUrl)
    dispatch(setSendFile(sendFile))
  }

  const listPages = useMemo(() => [...Array(totalPages).keys()], [totalPages])
  //List of value and label
  const selectListPages = listPages.map((p) => {
    return {
      value: p,
      label: p + 1,
    }
  })

  //Move stamp on pdf file
  const changeStampPosition = (label, cordX, cordAllY, cordRectX) => {
    setStampPositionState(label || '')

    //Ajusting coordinates to
    //fit the stamp if it is rotated
    setCoords((prev) => {
      return {
        x:
          (label.label.toLowerCase().includes('desno') && rotate === 90) ||
          (rotate === 270 && label.label.toLowerCase().includes('desno'))
            ? cordX - totalHeght
            : (label.label.toLowerCase().includes('lijevo') && rotate === 90) ||
                (rotate === 270 && label.label.toLowerCase().includes('lijevo'))
              ? cordX + totalHeght
              : rotate === 270 && label.label.toLowerCase().includes('lijevo')
                ? cordX - totalHeght / 2
                : cordX,
        y:
          label.label?.toLowerCase().includes('dole') && rotate === 90
            ? cordAllY + 40
            : label.label?.toLowerCase().includes('gore') && rotate === 90
              ? cordAllY - 60
              : label.label?.toLowerCase().includes('dole') && rotate === 180
                ? cordAllY + (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                : label.label?.toLowerCase().includes('gore') && rotate === 180
                  ? cordAllY - 60
                  : label.label?.toLowerCase().includes('gore') && rotate === 0
                    ? cordAllY - (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                    : label.label?.toLowerCase().includes('dole') && rotate === 0
                      ? cordAllY + totalHeght / 2
                      : label.label?.toLowerCase().includes('dole') && rotate === 270
                        ? cordAllY + 100
                        : cordAllY,
      }
    })
    setCoordsRect((prev) => {
      return {
        x:
          (label.label.toLowerCase().includes('desno') && rotate === 90) ||
          (rotate === 270 && label.label.toLowerCase().includes('desno'))
            ? cordX - totalHeght
            : (label.label.toLowerCase().includes('lijevo') && rotate === 90) ||
                (rotate === 270 && label.label.toLowerCase().includes('lijevo'))
              ? cordX + totalHeght
              : rotate === 270 && label.label.toLowerCase().includes('lijevo')
                ? cordX - totalHeght / 2
                : cordX - 1,
        y:
          label.label?.toLowerCase().includes('dole') && rotate === 90
            ? cordAllY + 40
            : label.label?.toLowerCase().includes('gore') && rotate === 90
              ? cordAllY - 60
              : label.label?.toLowerCase().includes('dole') && rotate === 180
                ? cordAllY + (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                : label.label?.toLowerCase().includes('gore') && rotate === 180
                  ? cordAllY - 60
                  : label.label?.toLowerCase().includes('gore') && rotate === 0
                    ? cordAllY - (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                    : label.label?.toLowerCase().includes('dole') && rotate === 0
                      ? cordAllY + totalHeght / 2
                      : label.label?.toLowerCase().includes('dole') && rotate === 270
                        ? cordAllY + 100
                        : cordAllY,
      }
    })
    setCoords1((prev) => {
      return {
        x:
          (label.label.toLowerCase().includes('desno') && rotate === 90) ||
          (rotate === 270 && label.label.toLowerCase().includes('desno'))
            ? cordRectX - (totalHeght >= 200 ? totalHeght / 4 : totalHeght / 5)
            : (label.label.toLowerCase().includes('lijevo') && rotate === 90) ||
                (rotate === 270 && label.label.toLowerCase().includes('lijevo'))
              ? cordX + totalHeght
              : rotate === 270 && label.label.toLowerCase().includes('lijevo')
                ? cordX - totalHeght / 2
                : cordRectX,
        y:
          label.label?.toLowerCase().includes('dole') && rotate === 90
            ? cordAllY + 40
            : label.label?.toLowerCase().includes('gore') && rotate === 90
              ? cordAllY - 60
              : label.label?.toLowerCase().includes('dole') && rotate === 180
                ? cordAllY + (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                : label.label?.toLowerCase().includes('gore') && rotate === 180
                  ? cordAllY - 60
                  : label.label?.toLowerCase().includes('gore') && rotate === 0
                    ? cordAllY - (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                    : label.label?.toLowerCase().includes('dole') && rotate === 0
                      ? cordAllY + totalHeght / 2
                      : label.label?.toLowerCase().includes('dole') && rotate === 270
                        ? cordAllY + 100
                        : cordAllY,
      }
    })
    setCoordsRect1((prev) => {
      return {
        x:
          (label.label.toLowerCase().includes('desno') && rotate === 90) ||
          (rotate === 270 && label.label.toLowerCase().includes('desno'))
            ? cordRectX - (totalHeght >= 200 ? totalHeght / 4 : totalHeght / 5)
            : (label.label.toLowerCase().includes('lijevo') && rotate === 90) ||
                (rotate === 270 && label.label.toLowerCase().includes('lijevo'))
              ? cordX + totalHeght
              : rotate === 270 && label.label.toLowerCase().includes('lijevo')
                ? cordX - totalHeght / 2
                : cordRectX - 1,
        y:
          label.label?.toLowerCase().includes('dole') && rotate === 90
            ? cordAllY + 40
            : label.label?.toLowerCase().includes('gore') && rotate === 90
              ? cordAllY - 60
              : label.label?.toLowerCase().includes('dole') && rotate === 180
                ? cordAllY + (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                : label.label?.toLowerCase().includes('gore') && rotate === 180
                  ? cordAllY - 60
                  : label.label?.toLowerCase().includes('gore') && rotate === 0
                    ? cordAllY - (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                    : label.label?.toLowerCase().includes('dole') && rotate === 0
                      ? cordAllY + totalHeght / 2
                      : label.label?.toLowerCase().includes('dole') && rotate === 270
                        ? cordAllY + 100
                        : cordAllY,
      }
    })
  }

  //Change page wich stamp is printed on
  const handleSetPage = (value) => {
    setStatePage(value)
    modifyPdf()
  }

  //Rotate stamp
  const handleRotate = (option) => {
    setRotate(option.value)
  }

  useEffect(() => {
    modifyPdf()
  }, [selectedFile, setSelectedFile, rotate, statePage])

  useEffect(() => {
    modifyPdf()
  }, [coords, coordsRect, statePage, rotate])

  useEffect(() => {
    modifyPdf()
  }, [file, fileTypes])

  useEffect(() => {
    //Ajust stamp position if stamp was rotated
    if (stampPositionState !== null) {
      //In case of custom stamp position
      const values = stampPositionState?.value
      changeStampPosition(stampPositionState, values[0], values[1], values[2])
    }
  }, [statePage, rotate])

  const { data: paginatedProtocolFiles } = usePaginatedProtocolQuery({
    page,
    pageSize,
    companyId: company?.id,
    query: query,
  })

  // paginated data

  const data = useMemo(
    () => (Array.isArray(paginatedProtocolFiles?.rows) ? paginatedProtocolFiles?.rows : []),
    [paginatedProtocolFiles],
  )

  return (
    <>
      <div className="pb-20 w-full overflow-x-auto lg:xl:max-w-7xl">
        <NewTable
          columns={columns}
          setPage={setPage}
          pageManual={page}
          data={data}
          setPageAmount={setPageSize}
          controlledPageCount={paginatedProtocolFiles?.totalPages || 0}
          hooksArray={tableHooksArray}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          count={paginatedProtocolFiles?.count}
          actions
          filtering
        />
      </div>
      <EditDocumentModal
        onClose={() => {
          setOpen(false)
          setSelectedFile()
          setFile({})
        }}
        file={file}
        open={open}
        changeStampPosition={changeStampPosition}
        stampPositionState={stampPositionState}
        setSelectedFile={setSelectedFile}
        handleSetPage={handleSetPage}
        selectedFile={selectedFile}
        selectListPages={selectListPages}
        handleRotate={handleRotate}
        setFile={setFile}
        fileTypes={fileTypes}
        setFileTypes={setFileTypes}
        page={statePage}
        urlPdf={urlPdf}
      />
    </>
  )
}
export default TableConfigFiles
