import React from 'react'

const TextInputRegular = ({ noLabel, label, onChange, ...props }) => {
  return (
    <fieldset className="flex flex-col w-full">
      {!noLabel && (
        <div className="w-full flex justify-start text-secondary">
          <label>{label}</label>
        </div>
      )}
      <input
        autoComplete="off"
        className="border-2 h-12 border-main-regular font-thin tracking-tight focus:border-neutral-200 placeholder:text-gray-300 w-full p-2 rounded-xl outline-neutral-400"
        onChange={onChange ? onChange : ''}
        {...props}
      />
    </fieldset>
  )
}

export default TextInputRegular
